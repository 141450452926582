<script>
import { computed, onMounted, ref, watch } from "vue";
import { useFetch, useMediaQuery, useTitle } from "@vueuse/core";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
export default {
  components: { IconArrowLeft },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isLargeScreen = useMediaQuery("(min-width: 1601px)");
    const type = computed(() => route.params.type);
    const fetchUrl = route.params.slug;
    const getItemData = computed(() => store.getters.getItemData);
    let isFinish = ref(false);
    const isSubParent = computed(() => {
      if (
        getItemData.value?.parent === 2 ||
        getItemData.value?.parent === 299 ||
        getItemData.value?.parent === 335
      ) {
        return true;
      } else {
        return false;
      }
    });
    const title = computed(() => {
      return getItemData.value?.title?.rendered;
    });

    function openSideBarOnLargeScreens() {
      if (isLargeScreen.value && route.path.includes("page")) {
        store.commit("TOGGLE_NEWS_SIDER_BAR");
      }
    }

    function refetch(id) {
      isFinish.value = false;
      const { data, isFinished } = useFetch(
        `https://admin.dss-sport.ru/wp-json/wp/v2/pages/${id}?_embed`
      )
        .get()
        .json();
      store.commit("SET_ITEM_DATA", {
        itemData: data,
      });
      isFinish.value = isFinished;
    }

    const goBack = () => router.go(-1);

    onMounted(() => {
      openSideBarOnLargeScreens();
      refetch(fetchUrl);
      useTitle(title);
    });

    watch(
      () => route.params.slug,
      () => {
        refetch(route.params.slug);
        useTitle(title);
      }
    );

    return {
      type,
      getItemData,
      goBack,
      isSubParent,
      isFinish,
    };
  },
};
</script>
<template>
  <div class="page page-view">
    <div class="page__wrapper">
      <h1 class="page__wrapper--title">
        {{ getItemData?.title?.rendered }}
      </h1>
      <span
        class="page__wrapper--back"
        v-if="!isSubParent && isFinish"
        @click="goBack"
      >
        <icon-arrow-left />
      </span>
      <div
        class="page__wrapper--child-links"
        v-if="getItemData?.childrens?.length > 0"
      >
        <ul class="sublinks">
          <router-link
            v-for="item in getItemData?.childrens"
            :key="item.id"
            :to="{
              name: 'Page',
              params: { type: type, slug: item.id },
            }"
            ><li class="sublink">{{ item?.title }}</li></router-link
          >
        </ul>
      </div>
      <div
        class="page__wrapper--preview"
        v-if="
          getItemData?._embedded?.['wp:featuredmedia']?.['0']?.source_url
            ?.length
        "
      >
        <img
          :src="getItemData?._embedded?.['wp:featuredmedia']?.['0']?.source_url"
          alt=""
        />
      </div>
      <div class="page__wrapper--content">
        <div
          class="page__wrapper--content"
          v-html="getItemData?.content?.rendered"
        ></div>
      </div>
    </div>
  </div>
</template>

<style lang="sass">
@import "../styles/scss/_mixins.scss"

.page
  max-width: $default-padding*51.5
  margin: 0 auto 0 $default-padding*7.5
  @media (max-width: $sizes-exl + $default-padding*16.25)
    margin: auto
  &__wrapper
    position: relative
    padding: $default-padding*2.8 0
    @media only screen and (max-width: 376px)
      width: 100%
      max-width: 365px
    &--back
      position: absolute
      top: 65px
      left: -65px
      transform: scale(1.2)
      cursor: pointer
      &.isShow
        opacity: 1 !important
    &--title
      font-size: $base-font-size*3
      font-weight: 800
      line-height: $base-font-size*3.4
      letter-spacing: 0.05em
      text-transform: uppercase
      max-width: $default-padding*40.6
      margin-bottom: $default-padding*2
      @media only screen and (max-width: $sizes-sm)
        font-size: $base-font-size*2
    &--child-links
      position: relative
      margin-bottom: $default-padding*2
      & .sublinks
        list-style: none
        @include d-flex(column, flex-start, flex-start, null, null, $default-padding*1.5)
        & .router-link-active.router-link-exact-active li
          color: $text-color
          &::before
            background: $text-color
        & .sublink
          line-height: $base-font-size*1.3
          color: $primary-color
          position: relative
          font-weight: 600
          transition: inherit
          &:hover
            color: $primary-color-light
          &::before
            position: absolute
            top: 0
            left: -($default-padding*2)
            content: ''
            width: 1px
            height: 21px
            background: $primary-color
            transform: rotate(90deg)
    &--preview
      & img
        width: 100%
        object-fit: cover
        border-radius: 10px
    &--content
      position: relative
      margin-top: $default-padding*2
      & h2
        font-size: $base-font-size*2.2
        font-weight: 500
      & p
        line-height: $base-font-size*1.65
      & ul
        list-style: none
        @include d-flex(column, flex-start, flex-start, null, null, $default-padding*1.2)
        & li
          position: relative
          line-height: $default-padding*1.6
          &::before
            position: absolute
            top: 6.5px
            left: -($default-padding*1.5)
            content: ''
            width: 9px
            height: 9px
            border-radius: 2px
            background: $primary-color
      & article
        background: #027fc70d
        border-radius: $default-padding*.7
        padding: $default-padding*2.5 $default-padding*2
        margin: $default-padding 0
        color: $primary-color
      & .btn
        width: fit-content
</style>
